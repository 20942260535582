<template>
    <ion-page>
        <CabeceraComponent 
            titulo="Seleccion de cuadro"
            color="primary"
            :isIOS="isIOS"
            :key="isIOS"
        />
          <ion-content class="ion-padding ion-margin">
                <ion-toolbar>
                    <ion-fab-button slot="end" color="darkdanger"  @click="goHome()" v-if="caso==='con_datos'" >
                      <i class="fas fa-backward en-fab"></i>  
                    </ion-fab-button> 
                </ion-toolbar>
                <ion-row v-if="camara">
                    <!--style="width: 500px;"-->
                    <QrcodeScannerComponent v-if="caso === 'sin_datos'" @result="onScan"  />
                </ion-row> 
                <ion-row v-if="!showme" class="spin ion-justify-content-center "  >
                    <ion-col  v-if="!showme" class="spin ion-align-self-center" size="48" > 
                        <ion-spinner  v-if="!showme"></ion-spinner>
                    </ion-col>
                </ion-row>

                <form class="pure-form pure-form-aligned form-grande" v-else-if="caso=='sin_datos'">
                    
                        <div class="pure-control-group">
                            <label for="aligned-pdv">Punto de Venta</label>
                            <input type="text" id="aligned-pdv" @click="mostrarpdvs()" :value="nompdv" placeholder="Seleccione Pdv"/>
                        </div>

                        <div class="pure-control-group">
                            <label for="aligned-otnum">Numero de Ot</label>
                            <input v-model="otnum" type="number" step="1" min="1" max="999999" pattern="/d+" id="aligned-otnum" placeholder="Numero de ot" />
                        </div>
                        <div class="pure-control-group">
                            <label for="aligned-pwd">Password</label>
                            <input v-model="codigo" type="number" step="1" min="1" max="999999" pattern="/d+" id="aligned-pwd" placeholder="Password" />
                        </div>

                        <div class="pure-control-group">
                            <div class="agrupa">
                            <ion-button  class="submitir2" 
                                :color="camara ? 'danger' : 'darksuccess'" 
                                @click="camara=!camara">
                                    <span v-if="!camara">Abrir<br/>Camara</span>
                                    <span v-else>Cerrar<br/>Camara</span>
                            </ion-button>
                            <ion-button  class="submitir" color="primariopure"
                                @click="check()"
                                >
                                    Consultar<br/>documentacion
                            </ion-button>
                            </div>
                        </div>
                </form>

                <ion-row v-else-if="caso==='con_datos'">

                    <ion-col size="48">
                        <h2>Documentacion O.T. {{otnum}}</h2>
                    </ion-col>
                    <ion-col size="48">
                        <h4>Punto de venta:  {{pdv}}</h4>
                    </ion-col>
                    <ion-col size="48" v-if="datos && datos.length>0">
                        <ion-list v-for="(f,index) in datos" :key="index">
                            <ion-item @click="verDoc(f)">
                                {{f}}
                            </ion-item>
                        </ion-list>
                    </ion-col>
                <ion-col size="48" v-else>
                    <h3>
                        No existen documentos para esta orden de trabajo.
                        Pongase en contacto con su punto de venta 
                    </h3>
                </ion-col>
                </ion-row>

            
          </ion-content>
    </ion-page>
</template>

<script>

    import { defineComponent } from 'vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import QrcodeScannerComponent from '@/components/QrScanComponent'
    //import BarcodeScannerComponent from '@/components/BarcodeScannerComponent'
    import { OpenAlertaPdvs , openAlertaV2} from  '@/utilities/services/alert-utilities';
    import { setParams } from '@/main';
    import { saveExpire } from '@/utilities/services/storage-utilities';
    import { Browser } from '@capacitor/browser';

    export default defineComponent({
        name : "HomePage",
        props : ['p_pdv', 'p_otnum','qt'], 
        components: { CabeceraComponent , QrcodeScannerComponent
        } , 
        data() {
            return {
                pdv: undefined, 
                nompdv: undefined, 
                otnum : undefined, 
                codigo : undefined, 
                caso: undefined , 
                datos: undefined, 
                isIOS: this.$route.meta.isIOS,
                dev: this.$route.meta.dev,
                showme: undefined , 
                camara: undefined, 
                q: undefined, 
                pdvs :[
                    { "k" : "119" , "v" : "119 - Cuadroges Palma" }, 
                    { "k" : "121" , "v" : "121 - Cuadroges Barcelona" }, 
                    { "k" : "136" , "v" : "136 - Cuadroges Córdoba" }, 
                    { "k" : "138" , "v" : "138 - Cuadroges Canarias" },
                    { "k" : "141" , "v" : "141 - Cuadroges Las Palmas" },
                    { "k" : "143" , "v" : "143 - Cuadroges Malaga" },
                ]
            }
        },

        async mounted() { 
            window.fake_console( 'Mounted', this.p_pdv , this.p_otnum)
            window.fake_console('Mounted', this.$route)
            await this.inicializacion()
            window.fake_console(this.isIOS)            
        },  
        async updated() {
                window.fake_console( 'Updated', this.p_pdv , this.p_otnum)
                window.fake_console('Updated', this.$route)
                this.pdv= undefined 
                this.nompdv= undefined 
                this.otnum = undefined 
                this.codigo = undefined 
                this.caso= undefined  
                this.datos= undefined 
                this.caso = "sin_datos"
                await this.inicializacion()
        },
        created() {
            window.fake_console( 'Created', this.p_pdv , this.p_otnum)
            window.fake_console('Created', this.$route)
            this.q = this.qt||Date.now()
            this.isIOS = this.$route.meta.isIOS 
            this.dev =  this.$route.meta.dev,
            window.fake_console(this.isIOS)
            },

        methods: {
            async goHome(){
                this.$goHome()
            },
            async inicializacion(){
                this.isIOS = this.isIOS ||this.$isIos
                this.dev = this.dev ||this.$dev
                this.q = this.qt||Date.now()
                this.caso = "sin_datos"
                if (this.p_pdv && this.p_otnum) {
                    this.pdv = this.p_pdv
                    this.otnum = this.p_otnum 
                    this.nompdv = this.pdvs.filter( x=> x.k == this.pdv)[0]['v']||undefined
                }
                this.showme = true 
            },
            async  onScan (decodedText, decodedResult) {
               window.fake_console(decodedResult,decodedText)
               const[ p_pdv, p_otnum ]= decodedText.split('/').slice(-2)
               window.fake_console({name : 'list',  params : { p_pdv, p_otnum  }})
               this.camara = !this.camara
               this.$router.replace({name : 'list',  params : { p_pdv: parseInt(p_pdv), p_otnum :parseInt(p_otnum) }})
            },

            async verDoc(i) {
                const {key, dev} = this.$params
                window.fake_console( {key, dev} )
            
                const url = `${this.$URL}/show/${i}?dev=${dev}&key=${key}`
                window.fake_console(url)
                await Browser.open ({ url})
            }, 
            async mostrarpdvs (){
                const r = await OpenAlertaPdvs(this.pdvs)
                window.fake_console(r)
                if(r.values) {
                    this.pdv = r.values
                    this.nompdv = this.pdvs.filter( x=> x.k == r.values)[0]['v']
                }
            }, 
            async list_docs() {
                this.showme = false 
                window.fake_console( {...this.$params})
                
                const d = await this.$http.post(`/list/${this.pdv}/${this.otnum}`, {...this.$params})
                this.datos = d.data
                window.fake_console(d.data)
                this.caso = 'con_datos'
                this.showme = true                
            }, 
            async check() { 
                let self = this
                if ( !this.pdv || /^[0-9]{3}$/.test(this.pdv)!==true ){
                    await openAlertaV2('Error Pdv', `El pdv no esta informado o no es valido` ) 
                }
                else
                if ( !this.otnum || /^[0-9]{1,6}$/.test(this.otnum)!==true ){
                    await openAlertaV2('Orde de Trabajo erronea', `La orden de trabajo no esta informada o es errónea` )
                    let ot = document.getElementById('aligned-otnum')
                    ot.focus()
                }
                else
                if ( !this.codigo || /^[0-9]{1,6}$/.test(this.codigo)!==true ){
                    await openAlertaV2('Password erroneo', `No se ha facilitado password o es erróneo. Debe ser un numero entero de hasta 6 digitos` )
                    let ot = document.getElementById('aligned-pwd')
                    ot.focus()
                }
                
                const {otnum, pdv, codigo} = this

                const payload = {otnum, pdv, codigo} 
                payload.dev = this.dev
                window.fake_console(payload)
                const chk = await this.$http.post('/check', payload)
                const data = chk.data 
                if (data.status === true) {
                    const {tk,ex} = data 
                    await saveExpire( {tk,ex} )
                    await setParams( tk, this.dev)
                    window.fake_console(this.$params)
                    window.fake_console('fin proceso inicial')
                    await self.list_docs()  
                    window.fake_console('lanzada peticion lista ')                    
                }
                else {
                    await openAlertaV2('Error de autenticación', 
                        `Los datos facilitados no concuerdan con ningun registro de cuadros.<br/>
                        Ponte en contacto con el punto de venta ` )
                    this.otnum = undefined
                    this.codigo = undefined
                
                }
                
                


        }}
})

</script>

<style scoped>
    ion-item, ion-list {
        width: 100% !important; 
        margin: auto !important; 
    }
    form, fieldset {
        max-width: 900px !important; 
    }
    .form-grande {
        font-size: larger !important; 
        
    }
    label {
        font-weight: 500 !important; 
    }
    input {
        min-width: 22rem !important; 
    }
    form, fieldset {
        margin: 0 1rem !important; 
        width: 80% !important;
        display: inline-block !important; 
    }

    .agrupa {
        float:right !important; 
    }
    .submitir{
        color: white;
        border-radius: 2px;
        margin: 0.8rem 0.4rem!important; 
        background: var(--ion-color-secondary) !important;
        height: 4rem !important; 
        width: 9rem !important;     
        text-transform: none !important;
        float: right !important; 
   }
    .submitir2{
        color: white;
        border-radius: 2px;
        margin: 0.8rem 0.4rem!important; 
        background: var(--ion-color-secondary) !important;
        height: 4rem !important; 
        width: 9rem !important;     
        text-transform: none !important;
        float:left !important;
        
   }
   .pure-control-group {
        width : 100% !important; 
   }
   
   .bordeado {
        border: 1px solid var(--ion-color-primary) !important;
    }

</style>